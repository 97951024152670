import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layouts/MdxLayout.js";
import SEO from "../../components/Seo";
import CiderQuery from "../../components/CiderQuery";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Cider" description="Natural Cider. Made Just Like Wine. 100% Fruit, Pressed and Aged Properly." mdxType="SEO" />
    <article className="mx-auto prose prose-xl">
      <h1 {...{
        "id": "cider"
      }}>{`Cider`}</h1>
      <h2 {...{
        "id": "our-ciders-are-made-just-like-wine"
      }}>{`Our ciders are made just like wine`}</h2>
      <p>{`This is cider for wine lovers. If you're not usually a cider fan, we might surprise you. We prefer the drier side of cider, pure and unmasked by sweeteners or additives. `}</p>
      <h2 {...{
        "id": "100-fruit-pressed-and-aged-properly"
      }}>{`100% fruit, pressed and aged properly`}</h2>
      <p>{`We press organic apples on-site, then age the juice in stainless tanks and oak barrels. Like wine, our ciders cellar exceptionally well in the bottle. Sometimes we even bottle-condition our ciders, like Champagne, allowing carbonation to develop naturally.`}</p>
      <p>{`Starting with the best fruit, and taking great care to age the cider properly, we achieve a refined and balanced product.`}</p>
      <h2 {...{
        "id": "continual-experimentation"
      }}>{`Continual experimentation`}</h2>
      <p>{`We're always developing new styles and co-fermentations, from apricot to cherry, rhubarb to lavender, dry to off-dry. `}<a parentName="p" {...{
          "href": "/club"
        }}>{`Join our club`}</a>{` to try our latest creations.`}</p>
      <hr></hr>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAADnklEQVQozwGTA2z8AIqNXYiJWmhsQU5WMVVYPVBYNzc7JD5GLlZcNUtSMFJaN1pcOE5UMXR1RpeVXY2GVpmPX7Gib56VYKmeZgBeYjtRWDNeazZiczlYYDpWYjpRXTFeazFsdzlzgT9ufT9aYjNjZziQklaCjkOIhkxxcECyrHGjoVZyczQAbW5CX2w1c4RAaHE9bXZCang+a3g9aXk0VmQqaHg6fY1Gj5ZLs7Nuqq5nc4I4pp9ifX9FlJtTuLdmiYZIAF5mNFlqKFpnNG9xRY6PV2NoO1hhMW5+NlxqK2Z2M4maR4KQPpObT5OZU4+UVMvFgJ2oU4KWOJagTrezawBVXS9MXBo9ShWChUmcmGJvcjxRVDJETSRaaShocjh4gz5ebCpVXyZqbDVdaC57f0SEkj6IlUKholael08ANT4hKTYSQk0baW0+bHBETFUkPUUcP0wca3MycnFCZ2Y7SVMhKjcQRlQcQUsZWV0nhIdJYWE+aWhFgXlIAImOXXV9WJKaZZmgbY+WaneCVXmEUoB/V4eAY2toRUxPJ0ROJVRcMH+KQ3eAPoCERVtgPTY5K0RGOEA/MACZqTydrUKaqEiZqUmZqEeXp0aYp0WapUSUokmJnEWIm0KRokudqlWutWC7u3fBuZVuZ19MSkM0NjOAfm4AkZw0iZUxipcwjJgwi5kyjZg5j5c8l5pHn5NHtpNDtIBEx59zx659zMd6zsp11shzuKBWdmxaMzIpkIBNAI2RNIqJPpCKSpeKUpuAVpp8VLGLZta4muC0l/GxjOGPa9ZxSNpnPuB7YtOXeYmAaG9tUnhyYTEwLltROQCdel2bcFuqYlG/bVXGZEfHZUbFbT7ZhEzTgErQlm/asYrtvKPbooqVYUdRSD5WV05cXFN0cGclJycLFyAAilU1t2I12HRC2XpBzGQ2xk4zolMoyWM8xFgy2GYx1npBlmVLUlRPRlJGTVNJTlRPNkA/KTdBJUBdVFtdAMOulLx/VL5wN8KFOLFfNqhHLrxhN8tWOMtUPq1vUFBJQTpHRE5WTUxPQzdBPik5QBUkLSM4TC1BVW9ydADbzLPMvaqvmXapbELSeDq9WzHFV0CSYUZVST81QkFNVUtdXlJTWE4+SEMkMzkOHSgeM0knPFNITVOKjI8AxLSa6tnB49XBt6OTtYp3u5Z2YE1CKDQ0P0pIXmBVYWNTUVVKSVNPLDk8ChgiDiExGzJIICw7bW91h4mQn4RyH2PZNf8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Archibald James apple bins",
            "title": "Archibald James apple bins",
            "src": "/static/1b47bb682f231915e425b49c4a3d5aa8/5a190/bins.png",
            "srcSet": ["/static/1b47bb682f231915e425b49c4a3d5aa8/772e8/bins.png 200w", "/static/1b47bb682f231915e425b49c4a3d5aa8/e17e5/bins.png 400w", "/static/1b47bb682f231915e425b49c4a3d5aa8/5a190/bins.png 800w", "/static/1b47bb682f231915e425b49c4a3d5aa8/c1b63/bins.png 1200w", "/static/1b47bb682f231915e425b49c4a3d5aa8/21b4d/bins.png 1280w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAIEAQMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAD/9oADAMBAAIQAxAAAAGeI1R7ioiVQx22Av/EABsQAAIDAAMAAAAAAAAAAAAAAAECAAMSERMi/9oACAEBAAEFArLSjd3sWAC1F2FTWpcgLBRzP//EABcRAAMBAAAAAAAAAAAAAAAAAAABEBL/2gAIAQMBAT8BMu//xAAXEQADAQAAAAAAAAAAAAAAAAAAARAS/9oACAECAQE/ATSv/8QAHRAAAQQCAwAAAAAAAAAAAAAAAQACEBExQRIhcv/aAAgBAQAGPwJ3m1V9chBLjpZOYDthXH//xAAdEAACAgIDAQAAAAAAAAAAAAABEQAhMWEQQXGR/9oACAEBAAE/IREDhXrhF7EgNKCwhhkMyInOo6mp+S2vRcapZ4//2gAMAwEAAgADAAAAEMA3Pv/EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxDIhP/EABcRAAMBAAAAAAAAAAAAAAAAAAEQESH/2gAIAQIBAT8QuVZJX//EAB4QAQADAQABBQAAAAAAAAAAAAEAESFBMWGBkaHx/9oACAEBAAE/EKguKgn5RG3Vg87v3GrQnkXsGmA4MsNN9cgcJSiR7PYAsSr4yMGrUhB8QNpdaqsQGgn/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Archibald James cider bottles",
            "title": "Archibald James cider bottles",
            "src": "/static/f1093003228068d2997240482bffb64d/4b190/archibald-james-four-cider-bottles.jpg",
            "srcSet": ["/static/f1093003228068d2997240482bffb64d/e07e9/archibald-james-four-cider-bottles.jpg 200w", "/static/f1093003228068d2997240482bffb64d/066f9/archibald-james-four-cider-bottles.jpg 400w", "/static/f1093003228068d2997240482bffb64d/4b190/archibald-james-four-cider-bottles.jpg 800w", "/static/f1093003228068d2997240482bffb64d/e5166/archibald-james-four-cider-bottles.jpg 1200w", "/static/f1093003228068d2997240482bffb64d/b17f8/archibald-james-four-cider-bottles.jpg 1600w", "/static/f1093003228068d2997240482bffb64d/9568a/archibald-james-four-cider-bottles.jpg 3024w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <h2 {...{
        "id": "cider-list"
      }}>{`Cider List`}</h2>
      <p>{`Our ciders are handcrafted in small batches and released on a rolling basis. Most are one-offs available to club members for a limited time. Some are reproduced with minor barrel-to-barrel variations and are available year-round. See `}<a parentName="p" {...{
          "href": "https://club.archibaldjames.com/shop"
        }}>{`online shop`}</a>{` for current availability.`}</p>
      <h3 {...{
        "id": "lineup"
      }}>{`Lineup`}</h3>
      <CiderQuery mdxType="CiderQuery" />
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAADnklEQVQozwGTA2z8AHhSL21DKYxRJ7h0MDIdCDcwKHZfTp5tPrZ/QZZgLVUtCl1NPZ+em5aKfpyCX5WCcG5UP55CJXVWRm5hVgB1TCaAVj2BUTaycztoRiZIPjd9cmyfe1qvf02TaD9IMSBURzqalpGOgHCfh2qLfXFqTjeULxVYPy1PQzcAtaOTybuwx761xbuwy8C2zMC3y8K708W8xKyct6GRo52Wu7iwycS8vbKmwLCfopJ/qZZ9i1I5PCUOUkY2ANXSzMnCua6onLqpmKiQeaKQe6iTfZuPeoKXg3yci3yompGxqbO2s7K2tamoo6Ock7uxoIVnSlI2GVVIOACplH51ZVKemJGwrKemoZi6ubHHxb2lubBkvrBarZxLp5FonYqll4qumoO0n4Opl4GymH6UcU9bPhxuXksAlYl3YlpOenNmenVpg4B2kYx/nZaIoJeHl4p7g3NlfWhYfmNOhmlOooVjvquPnZF6g3NcqoljsItiw6aHAJ+RgXpvZGZfU3JoWWBWR15SPl9QOGVUOmVSN19LNmRSPlxKN1xKN3puWZ6Zh5ONe4d1Xr6hfr+miK6UdQB0Z1dfUURQST5uZlhpYFFwZlRzZVF0Zk5yYUhkUz1vXklsWURoVUB7a1OYj3mQiHWJdVqsmX2kmYm1ppIAPDEiMCQXRkE4YVpNa2BPZVlGZVdCaltEbFxFZ1dBZlZBYlNAaFdCdGVPj4Zwh3xognJan5OAvKiM3LeIADQpGzQpHiciHE5IPF9URWNXRGVWQGpaQmZVPmNVQmNVQ1pMOFpKNnhpU4+GcHdqV49/abOdf9OtfcOiewBGOiklHBAUDwhoZFljXE1TRzRWSDJdTjdeTzhaTTpWSThMPytQQS1kVkF1aFSNgWyGeGOzkWeqjGmVgG0AQTYmKiEUDgkCR0E2gntsg3ppd2xYbF5IZlY/ZFQ/ZlVBbFpDe2hRjH1nnZJ9gHdlg21Tn4BdjHNZmoFnAEQ5Ki8lFxINBCwmGlNJOmNXRXZqVnttWHloUnloU3xsV3hoVHdnVHVoVHJmVFpPQKF6T39lSHljTZN5XwBIPSwyKBoWEAchGxJRRzVaTTpbTThbTTlbTTlYSzlVSDdUSDZZSzlhU0FmWEVUSTizgE1/XThURjaJbE8ATUEwNSocGhMKGBMLRjsrV0w4W045Wkw3V0o2U0c0TkMzUEQzV0o2XlE8XVA9TT8uqmszmGc3RTgoVkY2Z1py2Y8ipVkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Archibald James cider keg",
            "title": "Archibald James cider keg",
            "src": "/static/c54efd457e9a90d2ecb8cec2c0f38ba9/5a190/archibald-james-keg.png",
            "srcSet": ["/static/c54efd457e9a90d2ecb8cec2c0f38ba9/772e8/archibald-james-keg.png 200w", "/static/c54efd457e9a90d2ecb8cec2c0f38ba9/e17e5/archibald-james-keg.png 400w", "/static/c54efd457e9a90d2ecb8cec2c0f38ba9/5a190/archibald-james-keg.png 800w", "/static/c54efd457e9a90d2ecb8cec2c0f38ba9/c1b63/archibald-james-keg.png 1200w", "/static/c54efd457e9a90d2ecb8cec2c0f38ba9/21b4d/archibald-james-keg.png 1280w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEVUlEQVQ4ywFKBLX7AH5wYWteT4Z7Z2tEO4sABYUiHmRjV11cWFhVUGtpX4J0aqx3dHBCQU0tJmhCOo5laaFzdql8g8eUmdukoABuX1BrX1CUh3ZuT0R+BAd2GhVeXVKNjYlycGlHSEGgfn3el6WdW2BLKCNcNi9/X2SfeYGVaGeQZGOzfHsAZ1lMUkY6Zl5RZlBEagkJXxYSTUtBa2pmeXp1e4KEf3NvonFuvoSSzIiXeklJkXJ8wJClrHuImGpniV1aAFVIPmtbSVxTRFVCNlQKCj8NDDw6MWNeUnp9f3J4f2ZiVa2XhLWCf6d8f6J4fM6Wo59ibIZcY86boq5+gwA6MChYTD5MRDdHOS5CExI7FRIwKiFUUkuAj5l1fYGbjnynkneakH6rnZPBmaOmcW14TjuMZ1+we32kc3oAWVdXaGNfYVpTVkk/PhgULw4MKyIYXmdsg5OdjpCKoY93opaIqp6LtZh6r453sZBuxqV9xqB2vJNxpn1lAI6Mi46Jho2Hgn12cXVjXlhDPkM5LmFpbWp2fI+Ke5yMd6qhlaaTeLObfb2mh8OnhsqqhtGrf9avftyzgwC1rabPysaamJSXjYC5qZW8rpmUi39xcG1lbG2Mc1qkeFScj4Kdj3m0nYK4oYW/pIXFpYHLp3/OqHvFoXoA3OLn////yribk3pfjoR8gH+AjoZ8uqiRro9wiEwLnX9hipKaj35orpqBs5l8vZ99w594x6R8yaN4spqCAOzi0+bYvo+EdmJgYoR7cqKNc8Wqitq5lqttI6JuMdm8mbmggY97ZIt2YaeNcbKTcreWccKed76bdKahngDPxryZoaqWmqC5qZXLrojPsYnZvJu2fjmqbB3NrIXZu5TdvJTZto6mhWKFZkmYfF2kiGqvjWqoknunrrUAqrnMpaOh3MKk6syn5MOczKN9qoNarnMjw5Rk0KuCzaV52beR3byX6MOaxJxvfls5gGFDkXVZi4mJkpmkALy2s+HFpuvRsefMq9q1jmJINAAAADcmGKx2ScKSYbiGUseYZ9Wod76acpyLdol8bmlRPVU+J3R6gnh3egDawKToz7Ts1LjmyqnbtZOIZEo3Kh99XkTNn3PKm23Jm27Nn2+yj2uCfnh+goaIh4aulXmNb05KQDNZSDoA28Sq5c2z5cyw58yv6dC23Lue2riW17CKzqmB1q6I0ad8r5Z+h4ySh4+WkJOYs5qA4rqO4ruRnX1cPykVAODJsNnBptzFqeDIq93Gr+HKs9/Ept2/nNi4ldi2kayXgn+Gj4+UnY2Tm5uGctGrg82si9q0jsitkWxhVwDcxq7Yw6vUvqbZwqfcwaPfxaflyavfwqLhwp6wnIeCjZqHjZeZm6OejX68mnXHqYi4l3fIqoqRkI+PkJQA3MWs3Mm11sOuz7ec2b+h3b+f3r+f48Siy7GVlp2njZeklpmhmJGOt5Nt1bOPv55+2baQoJaKjI+RnJmWAnlJNwArlrcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Archibald James cider barrel",
            "title": "Archibald James cider barrel",
            "src": "/static/dfeadfa96135530f3e95757af5fd757f/5a190/cider-barrel-sample.png",
            "srcSet": ["/static/dfeadfa96135530f3e95757af5fd757f/772e8/cider-barrel-sample.png 200w", "/static/dfeadfa96135530f3e95757af5fd757f/e17e5/cider-barrel-sample.png 400w", "/static/dfeadfa96135530f3e95757af5fd757f/5a190/cider-barrel-sample.png 800w", "/static/dfeadfa96135530f3e95757af5fd757f/c1b63/cider-barrel-sample.png 1200w", "/static/dfeadfa96135530f3e95757af5fd757f/21b4d/cider-barrel-sample.png 1280w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      